.loading {
  inline-size: 100%;
  inset-block-start: 50%;
  margin: auto;
  overflow: show;
  position: fixed;
  z-index: 999;
}

.loading::before {
  background-color: rgb(255 255 255 / 50%);
  block-size: 100%;
  content: "";
  display: block;
  inline-size: 100%;
  inset-block-start: 0;
  inset-inline-start: 0;
  position: fixed;
}
