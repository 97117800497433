@use "breakpoints";
@use "colors";
@use "typography";

.membership-plans {
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
  padding: 0;
}

.plan {
  background-color: colors.$white;
  border: 1px solid rgb(0 0 0 / 15%);
  margin-inline: 8px;
  padding: 1.6rem;
  position: relative;
  user-select: none;

  input {
    display: none;
  }

  &:hover,
  &[aria-checked="true"] {
    cursor: pointer;
  }

  &:hover {
    border: 3px solid #aaa;
    padding: 1.4rem;
  }

  &[aria-checked="true"] {
    border: 3px solid #000;
    padding: 1.4rem;
  }

  & + .plan {
    margin-block-start: 8px;
  }
}

.plan-name {
  @include typography.subhead-semi-bold;

  color: colors.$black;
  display: block;
  margin-block-end: 16px;
  white-space: pre-wrap;

  &:hover {
    cursor: pointer;
  }
}

.plan-type {
  @include typography.eyebrow;

  background-color: colors.$black;
  color: colors.$white;
  display: inline-block;
  margin-block-end: 1.2rem;
  padding: 4px 8px;
}

.plan-cost {
  margin-block-end: 4px;
}

.plan-cost-amount {
  @include typography.subhead-semi-bold;

  color: colors.$black;
}

.plan-cost-term {
  @include typography.cta-small;

  margin-inline-start: 8px;
}

.commitment-term {
  @include typography.body-extra-small;

  color: colors.$dark-gray;
  display: block;
  font-size: 1.3rem;

  @media (min-width: breakpoints.$sm) {
    @include typography.cta-small;
  }
}

.no-plans {
  @include typography.subhead-regular;

  color: colors.$black;
  padding-block-end: 100px;
  padding-inline-start: 24px;

  @media (min-width: breakpoints.$lg) {
    padding-inline-start: 0;
  }
}

.change-club-button {
  @include typography.cta-small;

  padding-inline-start: 0;
}

@media (min-width: breakpoints.$lg) {
  .plan {
    margin-inline: 0;

    & + .plan {
      margin-block-start: 16px;
    }
  }

  .plan-name {
    white-space: normal;
  }
}
