@use "breakpoints";
@use "variables";

.personal-details {
  background: #f9f9f9;
  color: variables.$black;
  display: flex;
  flex-direction: column;
  margin-inline: auto;
  min-block-size: 100vh;
  overflow: hidden;
  position: relative;
}

.headline {
  flex: 0 0 100%;
}

.personal-info-wrapper {
  display: flex;
  flex: 1 0 100%;
  flex-direction: column;
  padding: 40px 24px 24px;
}

@media (min-width: breakpoints.$lg) {
  .personal-details {
    background: variables.$white;
    flex-direction: row;
  }

  .headline {
    flex-basis: 50%;
  }

  .personal-info-wrapper {
    block-size: 100vh;
    flex-basis: 50%;
    overflow-y: auto;
    padding: 120px 124px;
  }
}
