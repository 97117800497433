@use "breakpoints";
@use "buttons";
@use "colors";
@use "typography";

.personal-info {
  display: flex;
  flex-flow: row wrap;

  a {
    color: colors.$medium-gray;
    text-decoration: underline !important;
  }

  button[type="submit"] {
    @include buttons.button(buttons.$theme-primary-dark, buttons.$width-wide);

    display: inline-block;
  }

  p {
    inline-size: 100%;
  }
}

.form-field {
  /* stylelint-disable-next-line selector-class-pattern */
  :global .MuiInputBase-root {
    margin-block: 0;
  }
}

.field-first-name {
  flex: 0 0 calc(50% - 1.2rem);
  margin-inline-end: 2.4rem;
}

.field-last-name {
  flex: 0 0 calc(50% - 1.2rem);
}

.name-fields {
  display: flex;
  flex-wrap: wrap;

  > div {
    flex: 0 0 calc(50% - 12px);

    &:nth-child(odd) {
      margin-inline-end: 12px;
    }

    &:nth-child(even) {
      margin-inline-start: 12px;
    }
  }
}

.error-message-style {
  color: colors.$error-red;
  font-size: 14px;
  inset-block-start: -50px;
  padding: 0 12px;

  @media (min-width: breakpoints.$xs) {
    inset-block-start: -40px;
  }
}

.error-message-style-gdpr {
  inset-block-start: -25px;

  @media (min-width: breakpoints.$xs) {
    inset-block-start: -35px;
  }
}

.agree {
  @include typography.body-extra-small;

  color: colors.$medium-gray;
  font-size: 12px;
  line-height: 16px;
  margin-block: auto 2rem;
}

.legal {
  color: colors.$medium-gray;
  font-size: 12px;
  line-height: 1.5;
  margin: 40px auto;
}

.newsletter {
  align-items: center;
  display: flex;
  font-size: 14px;
  inline-size: 100%;

  svg {
    flex-shrink: 0;
  }

  input {
    block-size: 0;
    inline-size: 0;
    opacity: 0;
  }
}

.newsletter-copy {
  color: colors.$medium-gray;
  font-size: 12px;
  line-height: 1.5;
  margin-block-end: 40px;
  padding-inline-start: 40px;
}

@media (min-width: breakpoints.$lg) {
  .agree {
    margin-block-start: 40px;
  }
}
