@use "buttons";
@use "breakpoints";
@use "colors";

.submit-container {
  background-color: colors.$white;
  border-block-start: 1px solid colors.$light-gray;
  inline-size: calc(100% + 48px);
  inset-block-end: 0;
  inset-inline-end: 0;
  margin-block-start: auto;
  margin-inline-start: -24px;
  padding-inline: 24px;
  position: sticky;

  @media (min-width: breakpoints.$md) {
    inline-size: calc(100% + 240px);
    margin-inline-start: -120px;
    padding-inline: 120px;
  }
}

.cta {
  background-color: colors.$white;
  display: flex;
  flex-direction: column;
  inset-block-end: 0;
  inset-inline: 0;
  justify-content: left;
  padding: 16px 0 24px;
  z-index: 6;

  @media (min-width: breakpoints.$sm) {
    block-size: 100px;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px 0 0;
  }

  @media (min-width: breakpoints.$md) {
    background-color: colors.$white;
    block-size: 120px;
    flex-direction: row;
    inset-inline-start: 50vw;
    justify-content: start;
    padding: 24px 0 0;
  }
}

.cta-button {
  @include buttons.button(buttons.$theme-primary-dark, buttons.$width-wide);
}
