@use "breakpoints";
@use "colors";
@use "typography";

.gallery-container {
  margin-block-start: 64px;
}

.loader-container {
  align-items: center;
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  margin-block-start: 64px;
  min-block-size: 222px;

  @media (width > breakpoints.$sm) and (width < breakpoints.$lg) {
    min-block-size: 443px;
  }

  @media (width >= breakpoints.$lg) {
    min-block-size: 322px;
  }
}

.slide-media-details {
  @include typography.body-medium;

  display: flex;
  inline-size: 100%;
  margin-block-start: 16px;

  @media (width >= breakpoints.$lg) {
    margin-block-start: 24px;
  }
}

.slide-media {
  aspect-ratio: 16 / 9;
  position: relative;

  figure,
  picture {
    inset: 0;
    position: absolute;
  }
}

.slide-media-title {
  color: colors.$medium-gray;
  flex: 1;
  margin-inline: 16px 8px;
}

.slide-control-button {
  background-color: transparent;
  border: none;
  margin-inline-start: 16px;
  padding: 0;
}
