.responsive-video {
  margin: 0;

  video {
    block-size: 100%;
    inline-size: 100%;
    max-block-size: none;
    max-inline-size: none;
    object-fit: cover;
  }
}

.mute-toggle {
  background-color: transparent;
  border: 0;
  inset: 2.4rem 2.4rem auto auto;
  padding: 0;
  position: absolute;
  z-index: 10;

  svg {
    block-size: 3.6rem;
    display: block;
    inline-size: 3.6rem;
    opacity: 0.6;
  }

  &:hover {
    cursor: pointer;

    svg {
      opacity: 1;
    }
  }
}
