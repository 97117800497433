@use "colors";
@use "breakpoints";
@use "typography";

.email-code-flow {
  background-color: colors.$white;
  block-size: 100%;
  color: colors.$black;
  display: flex;
  flex-direction: column;
  min-block-size: 100vh;
  overflow: hidden;
  position: relative;

  @media (min-width: breakpoints.$sm) {
    block-size: 100%;
    overflow: visible;
  }

  @media (min-width: breakpoints.$md) {
    block-size: 100vh;
    flex-direction: row;
    overflow: hidden;
  }
}

.headline {
  flex: 0 0 100%;

  @media (min-width: breakpoints.$md) {
    flex-basis: 50%;
  }
}

.email-code-content {
  flex: 0 0 100%;
  scroll-behavior: smooth;

  // the overflow scroll is causing the spinner to size relative to
  // the viewport and not the .form-content, so we add an inner...
  .email-code-content-inner {
    min-block-size: 100%;
    padding: 24px;
    position: relative;

    /* stylelint-disable-next-line selector-class-pattern */
    :global .MuiFormControlLabel-root {
      margin-inline: 0;

      /* stylelint-disable-next-line selector-class-pattern */
      .MuiButtonBase-root.MuiCheckbox-root {
        margin-inline-start: 0;
      }
    }
  }

  @media (min-width: breakpoints.$sm) {
    block-size: 100%;
    margin: 0 auto;
    overflow: auto;

    .email-code-content-inner {
      padding: 52px 24px 100px;
    }
  }

  @media (min-width: breakpoints.$md) {
    block-size: 100vh;
    flex-basis: 50%;
    overflow: hidden;
    overflow: hidden auto;

    .email-code-content-inner {
      padding: 110px 120px 182px;
    }
  }
}

.loader {
  background: rgba(colors.$black, 40%);
  block-size: 100%;
  inline-size: 100%;
  inset-block-start: 0;
  inset-inline-start: 0;
  margin: 0;
  position: absolute;
  z-index: 10;
}

.email-code-main-label {
  color: colors.$black;
  inset-block-start: 2rem;

  @include typography.subhead-regular;
}

.email-code-sub-text {
  color: colors.$dark-gray;
  padding-block: 24px;

  @include typography.body;
}

.email-code-expiry-text {
  color: colors.$dark-gray;
  padding-block-end: 24px;
}

.email-code-expiry-text[data-expired="true"] {
  color: colors.$error-red;
}

.confirmation-text {
  color: colors.$black;
  padding-block-end: 16px;

  @include typography.body;
}

.resend-text {
  color: colors.$dark-gray;
  cursor: pointer;
  padding-block-start: 10px;
  text-decoration: underline;

  &:hover {
    color: colors.$dark-gray;
    text-decoration: underline;
  }

  @include typography.body-small;
}

.error-text {
  color: colors.$error-red;

  @include typography.body-small;
}

.otp-input {
  block-size: 66px;
  border: 1px solid #000;
  inline-size: 51px;
  margin-inline-end: 16px;
  padding: 12px;
  text-align: center;

  @include typography.subhead-semi-bold;
}

.responsive-image {
  img {
    inline-size: 100%;
    max-block-size: none;
    max-inline-size: none;
  }

  inline-size: 50%;
  object-fit: fill;
}

@media (min-width: breakpoints.$xs) and (max-width: breakpoints.$lg) {
  .responsive-image {
    display: none;
  }
}

@media (min-width: breakpoints.$lg) {
  .responsive-image-mobile {
    display: none;
  }
}
