@use "breakpoints";
@use "buttons";
@use "colors";
@use "typography";

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.exit-button {
  @include buttons.x-button;

  inset-block-start: 28px;
  inset-inline-end: 28px;
  outline: none;
  position: absolute;
  z-index: 10;

  &:focus {
    outline: 2px solid rgba(0, 0, 0, 60%);
    outline-offset: 1px;
  }
}

.dialog {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  background-color: colors.$white;
  border: 0;
  inline-size: calc(100% - 32px);
  inset-block-start: 50%;
  inset-inline-start: 50%;
  margin: 0;
  max-block-size: 468px;
  max-inline-size: 360px;
  opacity: 0;
  padding: 48px 16px 32px;
  position: fixed;
  transform: translate(-50%, -50%);
  user-select: none;
  z-index: 100;

  &::backdrop {
    background-color: rgba(colors.$black, 0.9);
  }

  &[open] {
    animation-name: fade-in;
  }
}

.title {
  @include typography.small-headline;

  color: colors.$black;
  margin-block-end: 2.4rem;
  white-space: pre-wrap;
}

.description {
  margin-block-end: 3.2rem;
}

.confirm-button {
  @include buttons.button(buttons.$theme-secondary-dark, buttons.$width-wide);

  margin-inline: auto;
}

.cancel-button {
  @include buttons.button(buttons.$theme-primary-dark, buttons.$width-wide);

  margin: 0 auto 1.6rem;
}

.backdrop {
  background-color: rgba(0 0 0 / 90%);
  block-size: 100%;
  inline-size: 100%;
  inset-block-start: 0;
  inset-inline-start: 0;
  position: absolute;
  z-index: 99;
}

.loader {
  background: rgba(colors.$black, 0.5);
  block-size: 100vh;
  inline-size: 100vw;
  inset-block-start: 0;
  inset-inline-start: 0;
  margin: 0;
  position: fixed;
  z-index: 10;
}

@media (min-width: breakpoints.$md) {
  .exit-button {
    position: fixed;

    &::before,
    &::after {
      background-color: colors.$black;
      block-size: 1px;
    }
  }

  .dialog {
    inline-size: 100%;
    max-inline-size: 464px;
    padding: 64px 32px 48px;
  }

  .buttons {
    padding-inline: 53px;
  }
}
