@use "colors";
@use "breakpoints";
@use "buttons";
@use "typography";
@import "mixins";
@import "custom";

.eqx-form {
  display: flex;
  flex-flow: row wrap;

  /* stylelint-disable-next-line selector-class-pattern */
  :global .MuiInput-label {
    color: colors.$dark-gray;
  }
}

.form-field {
  /* stylelint-disable-next-line selector-class-pattern */
  :global .MuiInputBase-root {
    margin: 0;
  }
}

.no-online-join-message {
  @include typography.body-small;

  color: colors.$gray;
  margin-block-start: 10px;
}

.first-name {
  display: flex;
  flex: 0 0 calc(50% - 1.2rem);
  flex-direction: column;
  margin-block: 0;
  margin-inline-end: 2.4rem;
}

.last-name {
  display: flex;
  flex: 0 0 calc(50% - 1.2rem);
  flex-direction: column;
  margin-block: 0;
}

.base-legal-text {
  @include typography.body-extra-small;

  color: colors.$dark-gray;
  margin-block-start: 6px;
}

.legal-text {
  @include typography.body-extra-small;

  color: colors.$dark-gray;
  margin-block-start: 6px;
  margin-inline-start: 3.3rem;

  a {
    margin-inline-start: 3px;
    text-decoration: underline;
  }
}

.legal {
  @extend .base-legal-text;

  inline-size: 100%;
  margin-block-start: 16px;
}

.uk-legal-text {
  @extend .legal-text;

  margin-block-end: 46px;
}

.uk-legal-sub-text {
  @extend .base-legal-text;

  margin-block-start: 46px;
}

.agreement-text {
  @extend .base-legal-text;

  inline-size: 100%;
  margin-block-start: 16px;
}

.radio-label {
  cursor: pointer;
  display: inline-block;
  inline-size: 100%;
  margin: 0 auto;

  @include media-breakpoint-down(md) {
    inline-size: auto;
    margin-inline-end: 12px;
  }

  @include media-breakpoint-down(sm) {
    inline-size: 100%;
  }

  input[type="radio"] {
    display: none;
  }
}

.nearest-clubs-container {
  margin: 15px auto 15px 0;

  .location {
    color: colors.$black;
  }
}

.other-location {
  background-color: transparent;
  border: none;
  display: block;
  margin-block-end: 2em;
  text-decoration: underline;
}

.cta-button-group {
  display: flex;
  flex-direction: column;
  inline-size: 100%;
  justify-content: space-between;
  margin-block-start: 50px;
  position: relative;

  @include media-breakpoint-up(md) {
    flex-direction: row;
  }
}

.cta-button {
  @include buttons.button(buttons.$theme-primary-dark, buttons.$width-wide);

  margin-block-end: 4.2rem;

  & + .cta-button {
    margin-block-start: 16px;
  }

  @include media-breakpoint-up(md) {
    & + .cta-button {
      margin-block-start: 0;
      margin-inline: 16px auto;
    }
  }
}

.api-error-message {
  color: colors.$error-red;
}

.error-message {
  @include typography.body-small;

  color: colors.$error-red;
  inset-block-start: 100px;
  inset-inline-start: 0;
  position: absolute;
}

.error-text {
  @include typography.body-extra-small;

  color: colors.$error-red;
  margin-block: -32px 16px;
}

.button-message {
  @include typography.body-small;

  background-color: colors.$light-gray;
  font-size: 1.3rem;
  inline-size: 100%;
  margin-block: 20px -28px;
  padding: 1px 5px;

  @media (width >= breakpoints.$sm) {
    font-size: 1.4rem;
  }
}

.club-select-container {
  flex: 0 0 100%;
  margin-block: 0 28px;
  position: relative;

  .club-select-label {
    color: colors.$medium-gray;
    font-size: 1.2rem;
    inset-block-start: 0;
    pointer-events: none;
    position: absolute;
  }

  &[data-blank="true"] {
    .club-select-label {
      color: colors.$black;
      font-size: 1.8rem;
      inset-block-start: 2rem;
    }
  }
}

.club-name-text {
  @include typography.body;

  border: 1px solid colors.$medium-gray;
  border-radius: 4px;
  color: colors.$black;
  cursor: pointer;
  padding: 1.5rem;
}

.club-name-text-error {
  border-block-end: 1px solid colors.$error-red;
  margin-block-end: 4px;
}

.error-text-club {
  @include typography.body-small;

  color: colors.$error-red;
  margin-block: -32px 16px;
}

.checkbox-label {
  font-family: inherit;
  max-inline-size: 500px;

  /* stylelint-disable-next-line selector-class-pattern */
  :global .MuiFormControlLabel-label {
    @include typography.body-small;
  }
}

.checkbox {
  color: colors.$black;
  margin-block-start: -16px;
  max-inline-size: 330px;

  /* stylelint-disable-next-line selector-class-pattern */
  &:global.Mui-checked {
    color: colors.$black;
  }
}

.checkbox-promo {
  margin-inline-start: 9px;
}

.checkbox-field {
  color: colors.$black;

  /* stylelint-disable-next-line selector-class-pattern */
  &:global.Mui-checked {
    color: colors.$black;
  }
}
