@use "breakpoints";
@use "buttons";
@use "colors";
@use "typography";

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.show-modal-button {
  background-color: transparent;
  border: none;
  padding: 0;
}

.dialog {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  background-color: colors.$white;
  border: 0;
  inline-size: calc(100% - 32px);
  inset-block-start: 50%;
  inset-inline-start: 50%;
  margin: 0;
  max-block-size: 468px;
  max-inline-size: 360px;
  opacity: 0;
  padding: 48px 16px 32px;
  position: fixed;
  transform: translate(-50%, -50%);
  user-select: none;
  z-index: 100;

  &::backdrop {
    background-color: rgba(colors.$black, 0.9);
  }

  &[open] {
    animation-name: fade-in;
  }
}

.title {
  @include typography.small-headline;

  color: colors.$black;
  margin-block-end: 2.4rem;
  white-space: pre-wrap;
}

.description {
  @include typography.body;

  margin-block-end: 3.2rem;
}

.confirm-button {
  @include buttons.button(buttons.$theme-primary-dark, buttons.$width-wide);

  margin: 0 auto 1.6rem;
}

.cancel-button {
  @include buttons.button(buttons.$theme-secondary-dark, buttons.$width-wide);

  margin-inline: auto;
}

.backdrop {
  background-color: rgba(0 0 0 / 90%);
  block-size: 100%;
  inline-size: 100%;
  inset-block-start: 0;
  inset-inline-start: 0;
  position: absolute;
  z-index: 99;
}

@media (min-width: breakpoints.$md) {
  .dialog {
    inline-size: 100%;
    max-inline-size: 464px;
    padding: 64px 32px 48px;
  }

  .buttons {
    padding-inline: 53px;
  }
}
