@use "typography";

.appointment-club-label {
  @include typography.subhead-regular;

  display: flex;
  margin-block-end: 0;

  dt {
    font-weight: 400;
  }

  dd {
    margin-block-end: inherit;
  }
}

.club-name {
  font-weight: 700;
  margin-inline-start: 5px;
}

.pencil-icon {
  cursor: pointer;
  margin-block-start: -3px;
  margin-inline-start: 1rem;
}
