@use "breakpoints";
@use "buttons";
@use "colors";
@use "links";
@use "typography";

.choose-membership-plan {
  display: flex;
  flex: 1 0 100%;
  flex-direction: column;
  padding: 0;
}

.lede {
  color: colors.$black;
  font-size: 20px;
  margin: 2.4rem;

  @media (min-width: breakpoints.$md) {
    font-size: 24px;
  }
}

.plans-container {
  &[data-sticky="true"] {
    margin-block-end: 72px;
  }
}

.change-club-option {
  margin: 40px 24px;

  .plan-description {
    color: colors.$medium-gray;
    margin-block-start: 10px;
  }
}

.personal-info-module {
  inline-size: 100%;
  margin-inline: -10px;

  [class^="personalInfo"] {
    > div {
      > div {
        flex-basis: 100%;
        max-inline-size: 100%;
      }
    }
  }
}

.plans-form {
  display: flex;
  flex: 1 1 100%;
  flex-direction: column;
  inline-size: 100%;
}

.error-message {
  color: colors.$error-red;
  padding: 15px 0;
}

.rejoin-message {
  color: colors.$black;
  padding-block-start: 22px;

  a {
    @include links.regular-text-link-dark;
  }
}

.unselected-time-container {
  align-items: center;
  background-color: colors.$light-gray;
  display: flex;
  flex-direction: row;
  margin-inline: 24px;
  max-inline-size: 350px;
  padding: 8px 12px;

  @media (min-width: breakpoints.$lg) {
    margin-inline: inherit;
    padding: 8px 16px;
  }
}

.error-icon-svg {
  inline-size: 16px;
  vertical-align: top;
}

.error-icon-text {
  font-size: 14px;
  line-height: 140%;
  margin: 3px 0 0 10px;
}

.cta-wrapper {
  inline-size: 100%;
  margin-block-start: auto;
  padding: 24px 24px 40px;

  button[type="submit"] {
    @include buttons.button(buttons.$theme-primary-dark, buttons.$width-wide);

    inline-size: 100%;
    margin-inline: auto;
  }

  &[data-sticky="true"] {
    background-color: colors.$white;
    border-block-start: 1px solid colors.$light-gray;
    inset-block-end: 0;
    inset-inline-start: 0;
    padding-block: 24px;
    position: fixed;

    @media (min-width: breakpoints.$md) {
      inline-size: 50%;
      inset-inline-start: 50%;
      padding-block-start: 32px;
    }
  }

  .purchase-cta {
    &[data-sticky="true"] {
      inline-size: 100%;
      inset-block-end: 0;
      z-index: 1000;
    }

    text-transform: capitalize;
  }
}

@media (min-width: breakpoints.$lg) {
  .lede {
    margin: 0 auto 24px;
  }

  .change-club-option {
    margin: inherit;
  }

  .choose-membership-plan {
    block-size: 100vh;
    overflow-y: auto;
  }

  .cta-wrapper {
    margin-block-start: auto;
    padding: 40px 0 0;

    button[type="submit"] {
      max-inline-size: 327px;
    }
  }

  .cta {
    inline-size: 280px;
  }
}

.visit-text {
  color: colors.$dark-gray;
  margin-inline: 24px;
  padding-block: 24px;

  @media (min-width: breakpoints.$lg) {
    margin-inline: inherit;
  }
}

.schedule-text {
  color: colors.$black;
  margin-inline-start: 5px;
  text-decoration: underline;

  &:hover {
    color: colors.$black;
    text-decoration: underline;
  }
}
