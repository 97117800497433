@use "colors";

.list {
  block-size: 1em;
  color: colors.$medium-gray;
  display: inline-block;
  list-style: none;
  margin: 0;
  overflow: hidden;
  padding: 0;
  vertical-align: bottom;
}

.item {
  animation-iteration-count: infinite;
  animation-name: itemAnimation;
  animation-timing-function: ease-in-out;
}
