@use "colors";
@use "breakpoints";

.corporate-flow {
  display: flex;
  flex-direction: column;

  @media (min-width: breakpoints.$md) {
    flex-direction: row;
  }
}

.headline {
  background-color: colors.$white;
  flex: 0 0 100%;

  @media (min-width: breakpoints.$md) {
    block-size: 100dvh;
    flex-basis: 50%;
    inset-block-start: 0;
    position: sticky;
  }
}

.form-content {
  background-color: colors.$white;
  color: colors.$black;
  flex: 0 0 100%;
  min-inline-size: 0;
  scroll-behavior: smooth;

  // the overflow scroll is causing the spinner to size relative to
  // the viewport and not the .form-content, so we add an inner...
  .form-content-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-block-size: 100%;
    padding: 24px;
    position: relative;

    > * {
      flex: 1;
    }

    &:has(> [data-is="ScheduleAppointment"]) {
      padding-block-end: 0;
    }

    /* stylelint-disable-next-line selector-class-pattern */
    :global .MuiFormControlLabel-root {
      margin-inline: 0;

      /* stylelint-disable-next-line selector-class-pattern */
      .MuiButtonBase-root.MuiCheckbox-root {
        margin-inline-start: 0;
      }
    }
  }

  @media (min-width: breakpoints.$sm) {
    .form-content-inner {
      padding: 52px 24px;
    }
  }

  @media (min-width: breakpoints.$md) {
    flex-basis: 50%;
    min-block-size: 100%;

    .form-content-inner {
      padding: 120px 90px;
    }

    [data-is="AppointmentActions"] {
      inline-size: calc(100% + 180px);
      margin-inline-start: -90px;
      padding-inline: 90px;
    }
  }
}
