@use "breakpoints";
@use "colors";
@use "mixins";
@use "typography";

.headline {
  color: colors.$white;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  position: relative;
}

.photo {
  grid-column: 1 / span 1;
  grid-row: 1 / span 2;
  padding-block-start: 57.6%;
  position: relative;
  z-index: 1;

  img {
    block-size: 100%;
    inline-size: 100%;
    inset-block-start: 0;
    inset-inline-start: 0;
    position: absolute;
  }

  &::after {
    background-color: rgb(0 0 0 / 50%);
    block-size: 100%;
    content: "";
    inline-size: 100%;
    inset-block-start: 0;
    inset-inline-start: 0;
    position: absolute;
    z-index: 2;
  }
}

.text {
  display: contents;
}

.eqx-logo {
  filter: invert(100%);
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;
  inline-size: 118px;
  margin: 18px 24px;
  z-index: 3;
}

.title {
  @include typography.large-headline;

  grid-column: 1 / span 1;
  grid-row: 2 / span 1;
  margin: auto 0 0;
  padding: 30px 24px 24px;
  white-space: pre-wrap;
  word-break: break-word;
  z-index: 3;
}

.eyebrow {
  @include typography.eyebrow;

  display: none;
}

.subcopy {
  @include typography.subhead-regular;

  color: colors.$off-black;
  font-weight: 500;
  grid-column: 1 / span 1;
  grid-row: 3 / span 1;
  margin: 0;
  padding: 8rem 2.4rem 0;
  z-index: 3;

  p {
    margin-block: 0;
    white-space: pre-wrap;

    + p {
      margin-block: 2.4rem 0;
    }
  }
}

.title + .subcopy {
  font-weight: 400;
}

.fine-print {
  @include typography.body-small;

  color: colors.$dark-gray;
  display: flex;
  padding: 1rem 2.4rem 0;
  white-space: pre-wrap;
  z-index: 3;

  a {
    text-decoration: underline;
  }
}

.selling-points {
  display: none;
}

@media (min-width: breakpoints.$sm) {
  .eqx-logo {
    inline-size: 178px;
  }
}

@media (min-width: breakpoints.$md) {
  .photo {
    grid-row: 1 / span 1;
    padding-block-start: 0;
  }

  .text {
    align-items: flex-start;
    block-size: 100%;
    display: flex;
    flex-direction: column;
    grid-column: 1 / span 1;
    grid-row: 1 / span 1;
    justify-content: flex-start;
    margin-block: auto;
    padding: 60px 100px;
  }

  .eqx-logo {
    margin-inline: auto;
    order: 3;
  }

  .title {
    padding: 0;

    &:not(:has(~ .subcopy)) {
      margin-block-end: auto;
    }
  }

  .subcopy {
    color: colors.$white;
    margin-block: auto;
    padding: 0;
  }

  .title + .subcopy {
    margin-block-start: 3.2rem;
  }

  .fine-print {
    color: colors.$gray;
    padding: 0;
  }
}

@media (min-width: breakpoints.$lg) and (max-width: breakpoints.$xl) {
  .text {
    padding: 60px 50px;
  }
}

body[data-vwo-web2665] {
  .headline {
    grid-template-rows: auto;

    .photo {
      grid-column: 1 / span 1;
      grid-row: 1 / span 3;
    }

    .eqx-logo {
      display: none;
    }

    .title {
      @include typography.medium-headline;

      grid-column: 1 / span 1;
      grid-row: 1 / span 1;
      margin-block-start: 0;
    }

    .subcopy {
      @include typography.subhead-semi-bold;

      color: colors.$white;
      grid-column: 1 / span 1;
      grid-row: 2 / span 1;
      padding-block: 0;
    }

    .selling-point-description {
      font-size: 1.4rem;
      line-height: 1.3;
      margin-block-start: 0.7rem;
    }

    .title + .subcopy {
      margin-block-start: 8rem;
    }

    .selling-points {
      display: grid;
      grid-column: 1 / span 1;
      grid-row: 3 / span 1;
      padding: 4.4rem 2.4rem 12.4rem;
      z-index: 3;
    }

    .selling-point {
      & + .selling-point {
        margin-block-start: 3.2rem;
      }
    }

    .selling-point-title {
      @include typography.cta;

      align-items: center;
      display: flex;
      list-style: none;

      &::-webkit-details-marker {
        display: none;
      }

      &::before {
        content: "•";
        display: inline-block;
        margin-inline-end: 0.8rem;
      }

      .caret {
        block-size: 0.7rem;
        inline-size: 1.2rem;
        margin-inline-start: 0.8rem;
        transition: transform 0.5s;
      }
    }

    .selling-point[open] {
      .caret {
        transform: rotate(180deg);
      }
    }

    @media (min-width: breakpoints.$md) {
      .text {
        grid-row: 1 / span 3;
      }

      .title {
        @include typography.medium-headline;
      }

      .subcopy {
        @include typography.subhead-semi-bold;

        font-weight: 400 !important;
      }

      .selling-point-title {
        @include typography.body-medium;
      }

      .selling-points {
        padding-inline: 0;
      }
    }
  }
}

body[data-vwo-web3321] {
  .fine-print {
    svg {
      block-size: 1.2em;

      path {
        fill: colors.$gray;

        @media (width <= breakpoints.$md) {
          fill: colors.$dark-gray;
        }
      }
    }
  }
}
